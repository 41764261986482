import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthData } from '../../models/auth-data.model';
import { LocalStorageService } from './local-storage.service';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  private readonly apiUrl: string;

  constructor(private http: HttpClient, private storageService: LocalStorageService) {
    this.apiUrl = environment.apiUrl + '/authentication';
  }

  public authenticate(email: string, password: string): Observable<AuthData> {
    const jsonStr = `{ "email": "${email}", "password": "${password.replace(/['"]+/g, '')}"}`;
    const encoded = btoa(jsonStr);

    return this.http.post<AuthData>(this.apiUrl + `/authenticate/${encoded}`, null);
  }

  public refreshToken() {
    const refreshToken = this.storageService.getRefreshToken();
    return this.http.post(this.apiUrl + `/refresh-token/${refreshToken}`, null);
  }
}
