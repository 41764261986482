import { Injectable } from '@angular/core';
import { ActiveToast, ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  constructor(private toast: ToastrService) {}

  public success(
    content: string,
    title: string = null,
    duration: number = 2000
  ): void {
    this.toast.success(content, title, {
      timeOut: duration,
    });
  }

  public info(
    content: string,
    title: string = null,
    duration: number = 2000
  ): void {
    this.toast.info(content, title, {
      timeOut: duration,
    });
  }

  public warning(
    content: string,
    title: string = null,
    duration: number = 3000
  ): ActiveToast<any> {
    return this.toast.warning(content, title, {
      timeOut: duration,
    });
  }

  public error(
    content: string,
    title: string = null,
    duration: number = 4000
  ): void {
    this.toast.error(content, title, {
      timeOut: duration,
    });
  }

  public remove(toastId): void {
    this.toast.remove(toastId);
  }
}
