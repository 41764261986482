import { Injectable } from '@angular/core';
import { Patient } from '../../models/patient.model';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {
  private openPatientsWithUserId = new Map<number, any>();
  private patientsWithUserId = new Map<number, any>();

  constructor() {
  }

  private static getViewedPatientsFromAllUsers(): Map<number, { patient: Patient; time: Date }[]> {
    return new Map(JSON.parse(localStorage.getItem('pnamveuaIFsfsaa')));
  }

  private static getViewedOpenPatientsFromAllUsers(): Map<number, Patient[]> {
    return new Map(JSON.parse(localStorage.getItem('pVIPnamveuaIOBa')));
  }

  private static setOpenPatientsForAllUsers(map: Map<number, Patient[]>): void {
    localStorage.setItem('pVIPnamveuaIOBa', JSON.stringify(Array.from(map)));
  }

  private static setViewedPatientsForAllUsers(map: Map<number, { patient: Patient; time: Date }[]>): void {
    localStorage.setItem('pnamveuaIFsfsaa', JSON.stringify(Array.from(map)));
  }

  public clear() {
    const clinicLocationId = this.getClinicLocationId();
    const allOpen = LocalStorageService.getViewedOpenPatientsFromAllUsers();
    const all = LocalStorageService.getViewedPatientsFromAllUsers();
    localStorage.clear();
    LocalStorageService.setOpenPatientsForAllUsers(allOpen);
    LocalStorageService.setViewedPatientsForAllUsers(all);
    this.openPatientsWithUserId = LocalStorageService.getViewedOpenPatientsFromAllUsers();
    this.patientsWithUserId = LocalStorageService.getViewedPatientsFromAllUsers();
    this.setClinicLocationId(clinicLocationId);
  }

  public getWebAppVersion(): number {
    return parseInt(localStorage.getItem('hoiaASfob'), 10);
  }

  public setWebAppVersion(version: number): void {
    localStorage.setItem('hoiaASfob', version.toString());
  }

  public getAccessToken(): string {
    return localStorage.getItem('AidmeAicvnapApv');
  }

  public setAccessToken(token: string): void {
    return localStorage.setItem('AidmeAicvnapApv', token);
  }

  public getRefreshToken(): string {
    return localStorage.getItem('Vjkfpea9Avcpv');
  }

  public setRefreshToken(token: string): void {
    return localStorage.setItem('Vjkfpea9Avcpv', token);
  }

  public getAccessTokenExpirationTime(): Date {
    return new Date(JSON.parse(localStorage.getItem('uiocjasUOCB')));
  }

  public setAccessTokenExpirationTime(date: Date): void {
    localStorage.setItem('uiocjasUOCB', JSON.stringify(date));
  }

  public setUserType(type: string): void {
    localStorage.setItem('sapuvepqua83', type);
  }

  public getUserType(): string {
    return localStorage.getItem('sapuvepqua83');
  }

  public setUserFullName(name: string): void {
    localStorage.setItem('sapuvepjr34k', name);
  }

  public getUserFullName(): string {
    return localStorage.getItem('sapuvepjr34k');
  }

  public setUserId(id: number): void {
    this.openPatientsWithUserId = LocalStorageService.getViewedOpenPatientsFromAllUsers();
    this.patientsWithUserId = LocalStorageService.getViewedPatientsFromAllUsers();
    localStorage.setItem('sapuvep3fsa3', id.toString());
  }

  public getUserId(): number {
    return parseInt(localStorage.getItem('sapuvep3fsa3'), 10);
  }

  public setClinicId(clinicId: number): void {
    localStorage.setItem('ipvenali', clinicId.toString());
  }

  public getClinicId(): number {
    return parseInt(localStorage.getItem('ipvenali'), 10);
  }

  public setClinicLocationId(clinicLocationId: number): void {
    localStorage.setItem('vpieabvpeabnoas', clinicLocationId.toString());
  }

  public getClinicLocationId(): number {
    return parseInt(localStorage.getItem('vpieabvpeabnoas'), 10);
  }

  public setOpenPatients(patients: Patient[]): void {
    this.openPatientsWithUserId.set(this.getUserId(), patients);
    localStorage.setItem('pVIPnamveuaIOBa', JSON.stringify(Array.from(this.openPatientsWithUserId.entries())));
  }

  public getOpenPatients(): Patient[] {
    const patients: Map<number, Patient[]> = new Map(JSON.parse(localStorage.getItem('pVIPnamveuaIOBa')));
    return patients?.get(this.getUserId());
  }

  public setViewedPatients(patients: { patient: Patient; time: Date }[]): void {
    this.patientsWithUserId.set(this.getUserId(), patients);
    localStorage.setItem('pnamveuaIFsfsaa', JSON.stringify(Array.from(this.patientsWithUserId.entries())));
  }

  public getViewedPatients(): { patient: Patient; time: Date }[] {
    const patients: Map<number, {
      patient: Patient;
      time: Date
    }[]> = new Map(JSON.parse(localStorage.getItem('pnamveuaIFsfsaa')));
    return patients?.get(this.getUserId());
  }

}
