import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./log-in-page/log-in-page.module').then((m) => m.LogInPageModule),
  },
  {
    path: 'admin',
    loadChildren: () =>
      import('./admin-page/admin-page.module').then((m) => m.AdminPageModule),
  },
  {
    path: 'agent',
    loadChildren: () =>
      import('./agent-page/agent-page.module').then((m) => m.AgentPageModule),
  },
  {
    path: 'assistant',
    loadChildren: () =>
      import('./assistant-page/assistant-page.module').then(
        (m) => m.AssistantPageModule
      ),
  },
  {
    path: 'doctor',
    loadChildren: () =>
      import('./doctor-page/doctor-page.module').then(
        (m) => m.DoctorPageModule
      ),
  },
  {
    path: 'technician',
    loadChildren: () =>
      import('./technician-page/technician-page.module').then(
        (m) => m.TechnicianPageModule
      ),
  },
  {
    path: 'confirm-appointment/:confirmationHash/:source',
    loadChildren: () =>
      import('./confirm-appointment-page/confirm-appointment-page.module').then(
        (m) => m.ConfirmAppointmentPageModule
      ),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
