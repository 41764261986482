import { Pipe, PipeTransform } from '@angular/core';
import { addHours } from 'date-fns';

@Pipe({
  name: 'dateClearOffset',
})
export class DateClearOffsetPipe implements PipeTransform {
  transform(value: Date, revert: boolean = false): Date {
    value = new Date(value);
    const offset = value.getTimezoneOffset();

    if (revert) {
      return addHours(value, offset / 60);
    } else {
      return addHours(value, -(offset / 60));
    }
  }
}
