import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { ActivatedRoute, Router } from '@angular/router';
import { LocalStorageService } from '../services/api-services/local-storage.service';
import { ToastService } from '../services/other-services/toast.service';
import { split } from 'ts-node';

@Injectable({
  providedIn: 'root',
})
export class AuthTokenInterceptor implements HttpInterceptor {
  constructor(
    private storageService: LocalStorageService,
    private toast: ToastService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const token = this.storageService.getAccessToken();

    let cloned: HttpRequest<object> = null;
    if (token !== null) {
      cloned = request.clone({
        headers: request.headers.set('Authorization', 'Bearer ' + token),
      });
    }

    const finalReq = cloned ? cloned : request;

    return next.handle(finalReq).pipe(
      catchError((err: HttpErrorResponse) => {
        let showToast = false;

        let title: string;
        let msg: string;

        switch (err.status) {
          case 0:
            title = `Napaka`;
            msg = 'Povezava s serverjem ni mogoča';
            showToast = true;
            break;
          case 401:
            const splicedUrl = finalReq.url.split('/');
            if (splicedUrl[splicedUrl.length - 1] === 'refresh-token') {
              this.router.navigate(['/']).then();
            }
            break;
          case 403:
            title = null;
            msg = 'Nimate dovoljenja za to akcijo.';
            showToast = true;
            break;
          case 404:
            break;
          case 406:
            localStorage.clear();
            break;
          case 409:
            break;
          default:
            title = `${err.status} Napaka`;
            msg = err.statusText;
            showToast = true;
            break;
        }
        if (showToast) {
          this.toast.error(title, msg);
        }
        throw err;
      })
    );
  }
}
